<template>
  <div class="relative flex flex-col justify-center gap-4 rounded-2xl border border-gray-100 px-6 py-8 text-center">
    <StarsRating :rating="rating" class="justify-center text-yellow" />
    <div>
      <p class="text-gray-600">{{ testimonial.attributes.content }}</p>
    </div>
    <div>
      <p class="text-sm text-lavender-800">{{ testimonial.attributes.reviewer }}</p>
      <p class="text-xs text-gray-500">{{ testimonial.attributes.reason }}</p>
    </div>
    <BlobTestimonialCard class="absolute bottom-0 left-0" />
  </div>
</template>

<script setup lang="ts">
import BlobTestimonialCard from "assets/svg/blobTestimonialCard.svg?skipsvgo";
import type { Testimonial } from "~/types/strapi/api/testimonial/content-types/testimonial/testimonial";

const props = defineProps<{
  testimonial: Testimonial;
}>();

const rating = computed(() => {
  switch (props.testimonial.attributes.rating) {
    case "one":
      return 1;
    case "two":
      return 2;
    case "three":
      return 3;
    case "four":
      return 4;
    case "five":
      return 5;
    default:
      return 0;
  }
});
</script>

<style scoped></style>
